import React from 'react';

const LandingPage = () => {
  return (
    <div className="bg-gray-900 text-white min-h-screen">
      {/* Hero Section */}
      <header className="bg-black py-16 text-center">
        <h1 className="text-5xl font-bold">Strategic Consulting & Bespoke Development</h1>
        <p className="mt-4 text-lg">Leverage our expertise for enterprise-level custom solutions.</p>
        <a
          href="#services"
          className="mt-8 inline-block bg-yellow-500 text-black px-8 py-4 rounded-full font-bold text-lg hover:bg-yellow-400 transition"
        >
          Explore Our Services
        </a>
      </header>

      {/* Services Section */}
      <section id="services" className="py-20">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold">Enterprise-Level Expertise</h2>
          <p className="text-lg mt-4">Tailored solutions for large-scale businesses.</p>

          <div className="mt-10 grid grid-cols-1 md:grid-cols-3 gap-10">
            <div className="p-6 bg-gray-800 rounded-lg">
              <h3 className="text-2xl font-bold">Enterprise Consulting</h3>
              <p className="mt-4">Strategic IT consulting to align technology with your business goals.</p>
            </div>
            <div className="p-6 bg-gray-800 rounded-lg">
              <h3 className="text-2xl font-bold">Bespoke Development</h3>
              <p className="mt-4">Custom software solutions built to scale with your business.</p>
            </div>
            <div className="p-6 bg-gray-800 rounded-lg">
              <h3 className="text-2xl font-bold">Long-Term Partnerships</h3>
              <p className="mt-4">Ongoing support and strategic advice to drive long-term growth.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="bg-yellow-500 py-20">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold text-black">Let's Build Your Future</h2>
          <p className="mt-4 text-black text-lg">Contact us to start your digital transformation today.</p>
          <a
            href="#contact"
            className="mt-6 inline-block bg-black text-yellow-500 px-8 py-4 rounded-full font-bold text-lg hover:bg-gray-800 transition"
          >
            Get in Touch
          </a>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-black py-6">
        <div className="container mx-auto text-center">
          <p>© 2024 10xbestdevelopment.com. All Rights Reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
